import React, { createContext, useContext } from 'react';
import Script from 'next/script';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const AnalyticsContext = createContext();

function AnalyticsProvider({ children }) {
  // whatever you set here as default, will be used as server side as well

  const GA_TAGS = publicRuntimeConfig.GA_TAGS || '';
  const TAGS = GA_TAGS.split(';')
    .map((tag) => tag.trim())
    .filter((tag) => tag.length > 0);

  return (
    <AnalyticsContext.Provider value={{ TAGS }}>
      {children}
      {TAGS.length > 0 && (
        <>
          {TAGS?.map((gaId) => (
            <Script key={gaId} async src={`https://www.googletagmanager.com/gtm.js?id=${gaId}`} />
          ))}

          <Script id="google-gtm">
            {`window.dataLayer = window.dataLayer || [];

            function gtag(){dataLayer.push(arguments);}

            gtag('js', new Date());
            
            ${TAGS?.map((gaId) => `gtag('config', '${gaId}')`).join(';')}`}
          </Script>

          <noscript>
            {TAGS?.map((gaId) => (
              <iframe
                key={gaId}
                title="gtm-iframe"
                src={`https://www.googletagmanager.com/ns.html?id=${gaId}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              ></iframe>
            ))}
          </noscript>
        </>
      )}
      {/* // TODO: Add support for facebook and other tag managers/analytics here */}
    </AnalyticsContext.Provider>
  );
}

function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
}

export { AnalyticsProvider, AnalyticsContext, useAnalytics };
